import { render, staticRenderFns } from "./PermisosTipos.vue?vue&type=template&id=df33c87a&scoped=true"
import script from "./PermisosTipos.vue?vue&type=script&lang=js"
export * from "./PermisosTipos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df33c87a",
  null
  
)

export default component.exports